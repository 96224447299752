@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap");

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #15112c;
}

h1 {
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glow-text-border {
  font-size: 6.2rem;
  animation: pulsateGreen 1.5s infinite alternate;
  border: 0.2rem solid #fff;
  border-radius: 2rem;
  padding: 0.4em;
  box-shadow: 0 0 0.2rem #38e19e, 0 0 0.2rem #38e19e, 0 0 2rem #38e19e,
    0 0 0.8rem #38e19e, 0 0 2.8rem #38e19e, inset 0 0 1.3rem #38e19e;
}

.glow-text {
  animation: pulsate 1.5s infinite alternate;
}

@keyframes pulsateGreen {
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #38e19e,
      0 0 80px #38e19e, 0 0 90px #38e19e, 0 0 100px #38e19e, 0 0 150px #38e19e;
  }

  0% {
    text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #fff, 0 0 10px #38e19e,
      0 0 45px #38e19e, 0 0 55px #38e19e, 0 0 70px #38e19e, 0 0 80px #38e19e;
  }
}

@keyframes pulsate {
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #fa13fe,
      0 0 80px #fa13fe, 0 0 90px #fa13fe, 0 0 100px #fa13fe, 0 0 150px #fa13fe;
  }

  0% {
    text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #fff, 0 0 10px #bc13fe,
      0 0 45px #bc13fe, 0 0 55px #bc13fe, 0 0 70px #bc13fe, 0 0 80px #bc13fe;
  }
}

@keyframes blinkingBlur {
  0% {
    backdrop-filter: blur(4px);
  }

  33% {
    backdrop-filter: blur(0px);
  }

  49% {
    backdrop-filter: blur(0px);
  }
  60% {
    backdrop-filter: blur(4px);
  }
  99% {
    backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(4px);
  }
}
.blinking-blur {
  animation: blinkingBlur 2s infinite;
}
