.App {
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes blinkPink {
  78% {
    color: inherit;
    text-shadow: none;
  }
  79% {
    text-shadow: 0px 0px 10px #ff39b0;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: none;
  }
  82% {
    text-shadow: 0px 0px 10px #ff39b0;
  }
  83% {
    color: inherit;
    text-shadow: none;
  }
  92% {
    text-shadow: 0px 0px 10px #ff39b0;
  }
  92.5% {
    color: inherit;
    text-shadow: none;
  }
}

@keyframes blinkBlue {
  78% {
    color: inherit;
    text-shadow: none;
  }
  79% {
    text-shadow: 0px 0px 10px #61dafb;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: none;
  }
  82% {
    text-shadow: 0px 0px 10px #61dafb;
  }
  83% {
    color: inherit;
    text-shadow: none;
  }
  92% {
    text-shadow: 0px 0px 10px #61dafb;
  }
  92.5% {
    color: inherit;
    text-shadow: none;
  }
}

@keyframes blinkLemon {
  78% {
    color: inherit;
    text-shadow: none;
  }
  79% {
    text-shadow: 0px 0px 10px #f9ff01;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: none;
  }
  82% {
    text-shadow: 0px 0px 10px #f9ff01;
  }
  83% {
    color: inherit;
    text-shadow: none;
  }
  92% {
    text-shadow: 0px 0px 10px #f9ff01;
  }
  92.5% {
    color: inherit;
    text-shadow: none;
  }
}

.blink-pink {
  animation: blinkPink linear infinite 3s;
}

.blink-blue {
  animation: blinkBlue linear infinite 4s;
}

.blink-lemon {
  animation: blinkLemon linear infinite 3s;
}

@keyframes blinkingIcon {
  0% {
    fill: #00ffff;
    filter: drop-shadow(0 0 10px #00ebff);
  }

  33% {
    fill: transparent;
  }

  49% {
    fill: #00ffff;
    filter: drop-shadow(0 0 10px #00ebff);
  }
  60% {
    fill: transparent;
  }
  99% {
    fill: transparent;
  }
  100% {
    fill: #00ffff;
    filter: drop-shadow(0 0 10px #00ebff);
  }
}
.blinking {
  animation: blinkingIcon 1.2s infinite;
}

/* Shiny button */
i {
  /*   positioning */
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  /*   gradient   */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(1%, rgba(255, 255, 255, 0.03)),
    color-stop(30%, rgba(255, 255, 255, 0.85)),
    color-stop(50%, rgba(255, 255, 255, 0.85)),
    color-stop(70%, rgba(255, 255, 255, 0.85)),
    color-stop(71%, rgba(255, 255, 255, 0.85)),
    color-stop(100%, rgba(255, 255, 255, 0))
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  );
  /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  );
  /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#00ffffff",GradientType=1 );
  /* IE6-9 */
  /*  forming the shine element
      play around with the width, skew and gradient to get different effects
    */
  width: 15%;
  height: 100%;
  transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  -moz-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);
  /*  animating it  */
  animation: move 2s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  -webkit-animation: move 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 1s;
  -moz-transform: skew(-10deg, 0deg);
  -moz-animation: move 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 1s;
  -ms-transform: skew(-10deg, 0deg);
  -ms-animation: move 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-delay: 1s;
  -o-transform: skew(-10deg, 0deg);
  -o-animation: move 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-delay: 1s;
}

/*  */
@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}
@-webkit-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}
@-moz-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 88%;
  }
}
@-ms-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}
@-o-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

/* banner */

.bar {
  top: 0;
  left: 0;

  width: 100%;
  padding: 5px 0;

  background-color: #ffdb2c;
  color: #071428;

  font-size: 16px;
}

.bar_content {
  display: block; /* Important to give the content a width */

  width: max-content;
  transform: translateX(0%); /* Animation start out of the screen */

  /* Add the animation */

  animation: move 45s linear infinite
    /* infinite make reapeat the animation indefinitely */;
}

/* Create the animation */
@keyframes move {
  to {
    transform: translateX(-100%);
  }
}
